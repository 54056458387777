// 404ページ
// 問い合わせ完了
// 検索
import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';

const NotFound = props => {
  //タイトル等
  const siteName = props.data.site.siteMetadata.title;
  const title = `404 NotFound | ${siteName}`;
  const description = 'ページが見つかりませんでした。';

  return (
    <Layout title={title} description={description} pageType="not-found">
      <h1 className="mb-2 text-2xl font-black">ページが見つかりませんでした</h1>
      <div className="w-full mb-5">
        <div className="text-center">
          <Link
            to="/"
            className="inline-block px-8 py-2 text-xl text-blue-300 border-solid border-2 border-blue-300"
          >
            Topへ
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;

export const notFoundQuery = graphql`
  query notFoundQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
